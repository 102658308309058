.table {


  td {
    padding: 1rem;
    &.detail {
      a {
        color: #000;
      }
    }
  }
}
.table__action {
  margin-top: 15px;
  .table__pagination {
    .btn {
      @include list-space(30px);
    }
    .btn-next,
    .btn-previous {
      background-color: #fff;
      height: 34px;
      line-height: 0;
      border-radius: 4px;
      border: 1px solid #e7e9ed;
      cursor: pointer;
    }
    .btn-next {
      span {
        i {
          margin-left: 10px;
        }
      }
    }
    .btn-previous {
      span {
        i {
          margin-right: 10px;
        }
      }
    }
  }
  .table__sizing {
    .btn {
      border-radius: 0;
      border: 1px solid #e7e9ed;
      padding: 6px 13px;
      background-color: #fff;
      color: #9a9fa8;
    }
  }
}

.table-content {
  background-color: #ffffff;
  .table-title-form {
    background-color: $color_border_dark;
  }
  .table-content-child {
    padding-left: 10%;
    .form-group {
      margin-bottom: 0;
    }
  }
  .table-content__block {
    margin: 0;
    padding: 20px;
  }
}
.table-bg {
  background-color: #fff;
  padding: 20px 20px 0 20px;
}

.table-info {
  .table {
    overflow-x: auto;
    white-space: nowrap;
  }
  .table-info__header {
    font-size: 15px;
    font-weight: 600;
    background-color: #dee5e7;
    border: 1px solid $color_border_dark;
    padding: 5px 15px;
  }
  > .col-md-12 {
    padding: 0;
  }
  .card-body {
    min-height: 0.01%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
