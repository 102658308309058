@import '../../styles/settings/variable.scss';


.container-full {
  background-color: #e8e8e8;
  height: 100%;
  font-weight: 400;
  margin-top: 20px;
}

.select-field-error .css-vj8t7z {
  border: 1px #dc3545 solid;
}

.field-none {
  display: none;
}
