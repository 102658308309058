$color_primary: #37bc9b;
$color_secondly: #70bbfd;

$color_border_light: #edf1f2;
$color_border_dark: #dee5e7;

$color-accent: #39b54a;
$color-accent-hover: darken($color-accent, 10%);

$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #2ca0f7;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;

$color-disabled: #dfe3e9;
//background

$color_bg_white: #fff;
$color-background: #e8e8e8;

$color-bg-topbar: #d5d5d5;
$color-nav-active: #0288d1;

$color-bg-sidebar: #b2b2b2;
$color-bg-sidebar-nav: #03a9f4;
$color-sidebar-active: #0054a6;
$color-sidebar-hover: #0082c9;

$color-border-table: #e3e6e8;

$color-border-input: #dfe3e9;
$color-border-input-hover: #ced0da;
$color-border-input-focus: #2ea2f8;
$color-border-input-error: #ed1c24;
$color-border-input-success: #1bb934;
