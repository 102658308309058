.container {
  max-width: 100%;
}

.content-container {
  padding-top: 80px;
  height: 100%;
  background-color: $color-background;
}

.container-inner {
  margin-top: 30px;
  padding: 0 50px;
}
.table-title-form {
  padding: 15px;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.switch-collapse .i-switch > i{
  // margin-top: 15px;
 
  padding: 10px 0px !important;
  background-color: transparent !important;
}
