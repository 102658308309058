.login-page {
  height: 91vh;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row-reverse;
  overflow-y: auto;
  &.login-page--photo {
    background: url('../../assets/images/bglogin.jpg') no-repeat center;
    background-size: cover;
  }
  .login-page__form {
    width: 44%;
    height: 100%;
    background-color: white;
    position: relative;
    .form-wrapper {
      width: 60%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: #fff;
      border-radius: 10px;
      padding: 75px 60px;
      .login-page__title {
        font-size: 24px;
        color: #0d90ca;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 30px;
      }
      .page-login__btn {
        margin: 45px 0 0 0;
        .btn {
          width: 100%;
          height: 50px;
          border-radius: 10px;
          text-transform: uppercase;
          font-size: 20px;
        }
      }
      &:before {
        content: '';
        width: 250px;
        height: 60px;

        position: absolute;
        top: -90px;
        left: 25%;
        right: 25%;
        background: url('../../assets/images/logo.png') no-repeat center;
        background-size: 250px;
      }
      .login-page__forget-pass {
        font-size: 16px;
        text-align: center;
        margin-top: 35px;
        a {
          color: #0d90ca;
        }
      }
    }
    .form {
      &.form--horizontal {
        .form__form-group-field {
          width: 100%;
          padding-left: 0;
          margin-left: 0;

          position: relative;
          i {
            position: absolute;
            top: 18px;
            right: 20px;
            font-size: 14px;
            color: #7d7d78;
          }
        }
      }
      input {
        background-color: #f1f1f1;
        border-radius: 10px;
        height: 50px;
        font-size: 16px;
        padding: 10px 35px 10px 20px;
      }
    }
  }
}

.logbtn{
  width: 95%;
}
.reglink{
float:right;
}

.reglink:hover{
  color: orangered;
}