.view-invoices-details {
  
  .view-invoices-details__form {
    width: 100%;
    min-height: 85vh;
    
    position: relative;
    padding-top: 20px;
    padding-bottom: 50px;
    justify-content: center;
    margin-top: 130px;
    // overflow: scroll;
    display: flex;
    .form-wrapper {
      width: 90%;
      background-color: #fff;
      border-radius: 0px;
      padding: 10px 10px;
    }
  }
}