.balances-manage-page {
  .balances-manage-page__form {
    width: 100%;
    min-height: 100vh;

    position: relative;
    padding-top: 5px;
    padding-bottom: 50px;
    justify-content: center;
    margin-top: 80px;
    // overflow: scroll;
    display: flex;
    .form-wrapper {
      width: 98%;
      background-color: #e8e7e7;
      border-radius: 0px;
      padding: 5px 10px;
    }
  }
}

.account-detail {
  margin-left: 10px;
}

.global-page .table-title-form-detail {
  background-color: white;
}

.card-headerb {
  background-color: rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  margin-top: 0px;
}

.account-assets,
.account-services,
.account-balance,
.account-transaction {
  border: 0px solid #dee5e7;
  background-color: #fff;
  padding: 0px;
}

.card-headerb .mb-0 {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.875rem;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  margin: auto;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-left: 0px;
}
.card-title-mb {
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

.btn-active {
  color: #354052 !important;
}

.btn-inactive {
  color: #aeb1b6 !important;
}
