.view-transactions-page {
  .transactions {
    .transactions-form {
      table {
        overflow-x: auto;
        white-space: nowrap;
      }
      

      .form{
        flex-wrap: inherit;

        .form__form-group{
          padding: 10px 10px 0px 0px;
        }

        //css drop down all page
        .css-1g6gooi{
          margin: 0px;
          padding-bottom: 0px;
          padding-top: 0px;
        }
        .css-1hwfws3{
          padding: 0px;
        }
        .css-1492t68{
          margin-left: 10px;
        }

        .css-xp4uvy{
          padding-left: 10px;
        }
   
      }
    }

    .table__pagination {
      margin-top: 10px;
      .btn {
        @include list-space(30px);
      }
      .btn-next,
      .btn-previous {
        background-color: #fff;
        height: 34px;
        line-height: 0;
        border-radius: 4px;
        border: 1px solid #e7e9ed;
        cursor: pointer;
      }
      .btn-next {
        span {
          i {
            margin-left: 10px;
          }
        }
      }
      .btn-previous {
        span {
          i {
            margin-right: 10px;
          }
        }
      }
    }

    .table__sizing {
      margin-top: 10px;
      .btn {
        border-radius: 0;
        border: 1px solid #e7e9ed;
        padding: 6px 13px;
        background-color: #fff;
        color: #9a9fa8;
      }
    }

    .btn-back{
      margin-top: 20px;
    }
  }
}
// textarea:focus, input:focus{
//   outline: none !important;
//   border: none !important;
// }

// textarea, input{
//   border: none !important;
// }

// .form-control:focus {
//   border-color: inherit;
//   -webkit-box-shadow: none;
//   box-shadow: none;
// }
// input:read-only{
//   background: rgb(245, 245, 245) !important;
// }