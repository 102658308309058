@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"), local("Poppins-Light"), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLDz8Z1xlEA.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJfedw.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLEj6Z1xlEA.ttf) format("truetype"); }

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(https://fonts.gstatic.com/s/poppins/v6/pxiByp8kv8JHgFVrLCz7Z1xlEA.ttf) format("truetype"); }

html {
  font-family: Poppins; }

form {
  width: 100%;
  font-family: Poppins; }

form-FormGroup {
  font-family: Poppins;
  font-weight: bold;
  color: #212529; }

.btnback {
  text-align: center;
  vertical-align: middle;
  user-select: none;
  color: white;
  background-color: #00c292;
  border-color: #00c292;
  font-size: 0.875rem;
  font-family: Poppins, sans-serif;
  line-height: 1.5;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  box-shadow: 0 0 0 0.2rem rgba(38, 203, 162, 0.5); }

.btnback:hover {
  color: #fff;
  background-color: #009c75;
  border-color: #008f6c; }

.btnmodify {
  color: white;
  background-color: #343a40;
  border-color: #343a40;
  margin-left: 15px;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  font-size: 0.875rem;
  font-family: Poppins, sans-serif;
  line-height: 1.5;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s; }

.manage-profile-page .manage-profile-page__form .form-wrapper {
  width: 95%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 10px; }

.manage-profile-page .manage-profile-page__form {
  width: 100%;
  background-color: #e9ecef;
  position: relative;
  padding-top: 20px;
  padding-bottom: 50px;
  justify-content: center;
  margin-top: 67px;
  display: flex;
  font-family: Poppins; }
