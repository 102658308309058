hr{
    margin-left: 320px;
    margin-top:50px;
   border-top: 1px solid grey;
}
.circle{
    width:50px;
    height: 50px;
    border-radius:100px;
    background: grey;
    text-align:center;
    line-height: 50px;
color: white;
}
.ulcircle{
  
       margin-top: -30px;
    text-align: center;
}

.ulcircle li{
       padding-left: 80px;
    margin-top: -47px;
  
    padding-right: 100px;
}
.reglogo{
   display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15%;
}
.ccardbtn{
    background: #1ec194;
    margin-left: 10px;
    color:white;
    margin-top:30px;

}
.uldetails{
    text-align: center;  
}
.uldetails li{
     padding-left: 50px;
 padding-right: 75px;
}
.uldetails li:last-child{
  
 padding-right: 40px;
}

.uldetails li:first-child{
  
 padding-left: -20px;
}
.lib-panel {
    background-color: #FFFFFF;  
}
.box-shadow {
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.10);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.10);
}
.lib-panel .lib-row {
    padding: 0 20px 0 20px;
    
}

.lib-panel .lib-row.lib-header {
    background-color: #FFFFFF;
    font-size: 14px;
    padding: 10px 20px 0 20px;
    
}

.lib-panel .lib-row.lib-header .lib-header-seperator {
    height: 2px;
    width: 26px;
    background-color: #d9d9d9;
    margin: 7px 0 7px 0;
}

.lib-panel .lib-row.lib-desc {
    position: relative;
    height: 100%;
    display: block;
    font-size: 13px;
}
.lib-panel .lib-row.lib-desc a{
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 20px;
}

.row-margin-bottom {
    margin-bottom: 20px;
}

.accdetailbtn{
    margin-left: 35%;
}

.textctr{
  vertical-align: middle; 
  padding-top: 25%;
  margin-left: 20%;
   display: flex;
  align-items: center;
   
    width: 100%;
}

.textctrcc{
     padding-top:300px;
    text-align: center;
}

.textctrsp{
    padding-top:40px;
    text-align: center;
}
.textctrsp li{
    margin-left: 10px;
}

.custbtn{
    background: #1fc0c8;
    color: white;
    width: 100%;
    border-radius: 0;
}

.bggreen{
    background: #17a455;
    align-items: center;
   
    
}
.bgsp{
  background: #17a455;
    align-items: center;
    height: 550px;  
}
.bgsp img{
     padding: 120px 0px;
    width: 100px;
}
.bgsp h2{
    color:white;
    text-align: center;
    margin-top:-90px;
    font-weight: 400;
}

.bgsp p{
color:white;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.bgcc{
    background:#1fc0c8;
    height: 540px; 
}
.bgcc img{
     padding: 120px 0px;
    width: 100px;
}
.bgcc h2{
    color:white;
    text-align: center;
    margin-top:-90px;
    font-weight: 400;
}
.bgcc p{
color:white;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.bgad{
   background: #17a455;
   height: 540px; 
}

.bgad img{
    padding: 120px 0px;
    width: 100px;
    
}
.bgad h2{
    color:white;
    text-align: center;
    margin-top:-90px;
    font-weight: 400;
}
.bgad p{
    color:white;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.bggreen img{
    padding: 70px 0px;
    width: 100px;
    
}
.bggreen h2{
    color:white;
    text-align: center;
    margin-top:-30px;
    font-weight: 400;
}
.bggreen p{
    color:white;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.bgcp{
    background: #17a455;
   height: 550px;   
}

.bgcp img{
    padding: 70px 0px;
    width: 100px;
    
}
.bgcp h2{
    color:white;
    text-align: center;
    margin-top:-30px;
    font-weight: 400;
}
.bgcp p{
    color:white;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
.packageul{
    margin-top: 10px;
   margin-left: 5px;
}

.package{
    padding: 5px 0px 5px 5px;
    margin: 0px;
    width: 100%;
}
.package i {
    float:right;
    background: #1ec194;
   padding: 20px 15px 20px 15px;
   margin-top: -35px;
   margin-left: 5px;
    color: white;
}
.itembottom {
   width: 100%;
    align-content: center;
    border-top: 1px solid #d9d9d9;
    margin-top: 10px;
    
}
.circle .active{
    background: #1fc0c8;
}
.lib-img-show{
    text-align: center;
    margin: auto;
    width: 100%;
}

.itembottom li{
    margin-left: 0px;
}

.items{
    margin-right: 5px;
    margin-top: 10px;
}
.cpimg{
   padding-left:20px; 
   margin-top: 10px;
list-style-image: url('../.././assets/images/libg.png');

}
.cpimg li{
   font-size: 10px;
}
.ccardbtn{
    background: #1ec194;
    margin-left: 10px;
    color:white;
    margin-top:27px;

}