$max-size: 0.5rem;
$medium-size: 0.41rem;
$min-size: 0.4rem;

body,
.mb-0 {
  font-size: $max-size !important;
}

button,
a {
  font-size: $max-size !important;
}

.profile-group {
  width: auto;
  padding-left: 0px;
  flex: 0 !important;
  li {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

.btn-full-screen {
  display: none;
}

.sidebarnav {
  a,
  span {
    padding: 2px !important;
    font-size: $max-size !important;
  }
}

.sidebar-nav {
  margin-left: 5px;
  .nav-menu {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 90px !important;
  }

  ul {
    padding-left: 0px !important;
  }
  a,
  span {
    padding: 2px !important;
    font-size: $max-size !important;
  }
  i {
    width: 22px !important;
  }
}

.collapse {
  width: auto !important;
  min-width: 120px !important;
  li {
    margin-left: 10px !important;
  }
}

// mobile version
@media only screen and (max-width: 470px) {
  .sidebar-nav {
    .nav-menu {
      margin-top: 90px !important;
    }
  }
}

// .activity-menu {
//   left: -60px !important;
// }

.menu-profile,
.dropdown-menu {
  width: auto !important;
  min-width: 120px !important;
}

.dropdown-submenu > .dropdown-menu {
  margin-left: 0px !important;
}

.form-group {
  margin-bottom: 5px !important;
  label,
  input,
  .react-select {
    font-size: $max-size !important;
  }

  // .wr-input input {
  //   height: auto;
  // }
}

.form__form-group {
  margin-bottom: 5px !important;
  label,
  input,
  .react-select {
    font-size: $max-size !important;
  }
}

.panel__title {
  font-size: $max-size !important;
}

.btn-flag {
  margin-left: 0px;
}

.sidebar-nav > ul > li {
  margin-top: 3px !important;
}

.title-account-number {
  line-height: 18px !important;
  span {
    font-size: $medium-size !important;
  }
}

.date-picker {
  z-index: auto;
}

.dashboard-page {
  margin-top: 10px !important;
}

.manage-page__form {
  margin-top: 150px !important;
}

.menu-item {
  margin-right: 10px;
}

.menu-item:hover,
.menu-item:focus,
.menu-profile-top:focus,
.menu-profile-top:hover {
  z-index: 9999999 !important;
}

.account-detail {
  font-size: $medium-size !important;
  padding: 3px !important;
}

.account-number {
  margin-right: 2px;
}

.card-title-mb {
  font-size: $max-size !important;
  display: flex;

  .title-form-header {
    margin-right: 20px;
  }
}

.btn-view-file {
  min-width: auto !important;
}

.manage-credit-card-page__form {
  margin-top: 150px !important;
}

.view-transactions-page,
.make-payment-page__form {
  margin-top: 150px !important;
}

.Toastify__toast-container {
  width: 300px !important;
}

.Toastify__toast-container--top-center {
  left: 8% !important;
}

.form-collapse .collapse__wrapper .panel__heading.panel__heading--btn .i-collapsible {
  margin-top: 5px;
}

.view-invoices-details__form {
  margin-top: 180px !important;
}

.form-wrapper {
  .view-inv-iframe {
    width: 92% !important;
    margin-left: 15px !important;
  }
}

// mobile version
@media only screen and (max-width: 395px) {
  .dashboard-page {
    margin-top: 45px !important;
  }

  .manage-page__form {
    margin-top: 180px !important;
  }

  .manage-page__form,
  .manage-credit-card-page__form,
  .make-payment-page__form,
  .view-transactions-page {
    margin-top: 200px !important;
  }
}
