.dashboard-page {
  width: 100%;
  display: block;
  min-height: 93vh;
  padding-top: 90px;

  header {
    .header-container {
      background-color: #212529;

      // padding: 1rem;
      // &:before {
      //   content: '';
      //     width: 170px;
      //     height: 50px;

      //   background: url('../../assets/images/logo.png') no-repeat center;

      // }

      // &::after {
      //   content: '';
      //   width: 216px;
      //   height: 50px;
      //   position: absolute;
      //   right: 40px;

      // }
    }
  }

  .dashboard-body {
    width: 100%;
    min-height: 100vh;
    background-color: #e9ecef;
    padding-top: 80px;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: Poppins, sans-serif;
    .redirect-button {
      width: 30%;
      background-color: #ffff;
      color: #555;
      border-radius: 10px;
      padding: 10px;
      margin: 10px 35%;

      cursor: pointer;
    }
  }

  .contact-agent {
    position: absolute;
    bottom: 100px;
    right: 50px;
    padding: 12px;
    background: rgb(210, 79, 23);
    color: white;
    width: 15%;
    height: 80px;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
  }
}

.modal-content {
  color: #555;
}

.dashboard-page .row {
  width: 99%;
  margin: auto;
  margin-top: 80px;
}
