.make-payment-page{

  .payment {
    padding: 5px 5% 20px 5%;
    .payment-form{
      padding: 25px 5% 20px 5%;
      
      .payment-form-left{
        width: 60%;
        float: left;

        .form-group{
          display: flex;
          
          .form__form-group-select{
            width: 100%;
          }
        }
        .form-groups-button{
          margin: auto;
          width: 70%;
          padding: 0px 0px 10px 50px;
          display: flex;  

        }

        input:focus{
          outline: none;
          border-color: #2ea2f8;
        }

      }

      .payment-form-right{
        padding: 0px 75px;
        width: 30%;
        float: left;
      }

    }
    .group-button-control{
      position: relative;
      flex-direction: row-reverse;
      padding: 0 15px;
      float: right;
      width: 100%;

      .group-btn-payment{
        float: right;
        margin: 10px;
      }

    }

    .group-payment-control{
      position: relative;
      float: right;
      width: 100%;
      display: flex;
    }

    .button-back{
      padding: 0 15px;
      float: left;

    }
  }

  .make-payment-card{
    .rccs{
      margin-left: 0px;

      .rccs__card--front{
        width: 120%;
        height: 120%;
      }
    }
  }

}