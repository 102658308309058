.view-invoices-page {
  
  
  .view-invoices-page__form {
    width: 100%;
    min-height: 85vh;
    position: relative;
    padding-top: 20px;
    padding-bottom: 50px;
    justify-content: center;
    margin-top: 67px;
    // overflow: scroll;
    display: flex;
    .form-wrapper {
      width: 96%;
      background-color: #fff;
      border-radius: 0px;
      padding: 10px 10px;
    }
  }
}



.card-title{
  font-weight: 500;
 
}

.card-titleinvoice{
 font-weight: 500;
 margin-left:-10px;
}
.card-subtitle {
  margin-left: -10px;
}

.mb-0 {
 padding-top: 0px;
 padding-bottom: 0px;
  font-size: .875rem;
}
.card-headerb {
  // margin: auto;
  width: 99%;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
   margin-left: 10px; 
}
.view-invoices-page .bills .pending-bills {
   border: 0px solid #dee5e7; 
}

.view-invoices-page .bills .close-open-bills {
  margin-top: 20px;
  border: 0px solid #dee5e7; 
}

