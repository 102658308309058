.customer-page {
  .form-collapse {
    .card {
      .card-body {
        padding: 0;
      }
    }
  }
}
.account-assets,
.account-services,
.account-balance,
.account-transaction {
  border: 1px solid $color_border_dark;
  background-color: #fff;
  padding: 20px;
  .table-info {
    margin-bottom:20px;
  }
}
.account-balance {
  > * {
    margin-bottom:20px;
  }
}
