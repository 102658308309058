// @import '~react-select/dist/react-select.min.css';
@import '~react-datepicker/dist/react-datepicker.css';
// @import '~rc-time-picker/assets/index.css';

.form-header{
  padding: 15px;
  background-color: #f6f8f8;
  border: 1px solid #dee5e7;

  .header-title{
    font-weight: 700;
    padding: 10px;

    .detail-name{
      font-size: 14px;
      margin-left: 50px;
      padding: 10px;
      background-color: #ffffff;
      color: #555555a0;
    }

    .detail-value {
      font-size: 14px;
      margin-left: 10px;
      padding: 10px;
      background-color:#ffffff;
      color: #555555a0;
    }
  }

  .large-font-size{
    font-size: 16px;
  }
}