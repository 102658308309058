.view-invoices-page{
  .bills {
    table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    
    .pending-bills{
      border: 1px solid #dee5e7;
    }
  
    .close-open-bills{
      margin-top: 20px;
      border: 1px solid #dee5e7;

      .oc-total-field{
        border-top: 1px solid #dee5e7;
        background-color: white;
        align-items: center;
        vertical-align: middle;
        padding-left: 40%;

        .bold-text{
          font-weight: 700;
        }

        .txt-error{
          color: #a94442;
        }
      }
    }

    .btn-back{
      margin-top: 10px;
    }
  }
}