.manage-profile-page {
  .manage-profile-page__form {
    width: 100%;
    min-height: 100vh;
    background-color: #e9ecef;
    position: relative;
    padding-top: 20px;
    padding-bottom: 50px;
    justify-content: center;
    margin-top: 67px;
    display: flex;
    font-family: Poppins;
    // overflow: scroll;
    display: flex;
    .form-wrapper {
      width: 96%;
      background-color: #fff;
      border-radius: 2px;
      padding: 20px 10px;
    }
  }
}

.profile {
  .form-wrapper {
    background-color: transparent !important;
  }
}
