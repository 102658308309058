@mixin not-last() {
  .temp,
  &:not(:last-child) {
    @content;
  }
}

@mixin list-space($value) {
  @include not-last {
    margin-right: $value;
  }
}