.view-transactions-page {
  
  
  .view-transactions-page__form {
    width: 100%;
    // min-height: 100vh;
    
    position: relative;
    padding-top: 20px;
    padding-bottom: 50px;
    justify-content: center;
    margin-top: 67px;
    // overflow: scroll;
    display: flex;
    .form-wrapper {
      width: 96%;
      background-color: #fff;
      border-radius: 0px;
      padding: 10px 10px;
    }
  }
}
.transaction-date-picker{
  display: flex;
  .icon-date-picker{
    width: auto;
    margin-left: -30px;
    margin-top: 8px;
    margin-bottom: 8px;
    color: hsl(0,0%,80%);
    border-left: 2px solid hsl(0,0%,80%);
    z-index: 2000;
  }
}