.form__form-group-field {
  display: flex;
  input {
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    height: 40px;
    transition: border 0.3s;
    border-radius: 4px;
    font-family: Poppins, sans-serif;
    background-color: #fff;
    border: 1px solid #dfe3e9;
    outline: none;
  }

  .form__form-group-icon{
    padding: 6px;
    height: 40px;
    border: 1px solid #dfe3e9;
    background-color: #dfe3e9;
    border-radius: 0 4px 4px 0;
  }
}
