.wr-input {
  position: relative;
  .wr-input-icon {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  input {
    height: 40px;
  }
}
