.register-page {
  min-height: 100vh;

  .register-page__form {
    width: 100%;
    background-color: #e9ecef;
    position: relative;
    padding-bottom: 30px !important;
    padding-top: 10px !important;
    justify-content: center;
    margin-top: 0px !important;
    display: flex;
    font-family: Poppins;
    display: flex;
    .form-wrapper {
      width: 96%;
      background-color: #fff;
      border-radius: 2px;
    }
  }
  .logo-company {
    width: 150px;
    display: block;
    margin: auto;
  }

  .RSPBprogressBar {
    width: 80%;
    margin: auto;
  }
  .number-loader-progress {
    background: #676666;
    line-height: 50px;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-color: transparent;
    text-align: center;
    font-size: 20px;
    margin-top: 15px;
    -webkit-box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.39);
    box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.39);
  }

  .title-loader-progress {
    white-space: nowrap;
    color: #6c757d;
  }
  .number-loader-active {
    background-color: #1e7e34;
  }

  .text-create-package {
    margin-top: 5px;
    margin-left: -38px;
  }

  .text-select-package {
    margin-top: 5px;
    margin-left: -60px;
  }

  .text-credit-card {
    margin-top: 5px;
    margin-left: -35px;
  }

  .text-account-detail {
    margin-top: 5px;
    margin-left: -23px;
  }

  .form-register {
    // padding: 20px 10px;
    height: 540px;
    padding: 0px;
    margin: 50px auto 50px auto;
    background-color: rgb(250, 250, 250);
    width: 95%;
    -webkit-box-shadow: 0px 0px 47px -13px rgba(0, 0, 0, 0.62);
    -moz-box-shadow: 0px 0px 47px -13px rgba(0, 0, 0, 0.62);
    box-shadow: 0px 0px 47px -13px rgba(0, 0, 0, 0.62);

    .group-button {
      position: absolute;
      bottom: 10px;
      width: 96%;
      text-align: center;
    }

    .single-btn {
      float: right;
      margin-bottom: 20px;
    }

    .btn-center {
      position: relative;
      width: 130px;
      right: auto;
    }
    .btn-submit {
      margin-left: 15px;
    }

    .multi-btn {
      margin-right: -145px;
    }

    .form-input {
      margin-top: 15px;
    }

    .no-padding {
      padding: 0px !important;
    }

    .select-package-from {
      .arrow-handle-page {
        margin: auto 10px;

        color: #babdc0;
      }

      .icon-handle-page {
        background: #fff;
        width: 40px;
        height: 40px;
        line-height: 35px;
        border-radius: 50%;
        -webkit-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.61);
        -moz-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.61);
        box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.61);
      }

      .icon-arrow {
        text-align: center;
        line-height: 35px;
        font-size: 25px;
      }
    }
    .group-item-package {
      margin-bottom: 20px;
    }

    .package-container {
      border-bottom: 1px solid;
    }

    .bundle-container {
      margin-top: 20px;
    }

    .title-bunlde-selected {
      text-align: center;
    }

    .item-bundleId {
      height: auto;
      background: #fff;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.61);
      -moz-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.61);
      box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.3);
      height: 68px;
    }

    .description-bundle {
      font-size: 10px;
    }

    .ic-remove-bundle {
      padding-right: 3px;
      font-size: 20px;
    }

    .item-package {
      background: #fff;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.61);
      -moz-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.61);
      box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.3);
      height: 60px;
    }

    .package-id {
      font-size: 13px;
      word-wrap: break-word;
      // line-height: 25px;
      // &.no-description {
      //   line-height: 70px;
      // }
    }
    .package-name {
      font-size: 13px;
      word-wrap: break-word;
      // line-height: 40px;
    }

    .item-bundle-id {
      max-height: 90px;
      overflow: auto;
      width: auto;
      // white-space: nowrap;
    }
  }

  .view-detail-left {
    background: #0f9d20de;
    height: 100%;
  }

  .image-group {
    width: 150px;
    height: 200px;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .title-slected-step {
    color: #fff;
    text-align: center;
    font-size: 25px !important;
  }

  .icon-show-description {
    font-size: 25px;
    color: #fff;
    text-align: center;
    margin: auto;
  }

  .slick-track {
    float: left;
  }

  .group-handle-description {
    background: #1e7e34;
  }

  .text-detail-step {
    color: #fff;
  }

  .select-package-container {
    .css-1szy77t-control {
      border: none;
      box-shadow: none;
      background-color: #fafafa;
    }

    .css-bg1rzq-control {
      outline: none !important;
      background-color: #fafafa;
      border: none;
    }

    .css-1hwfws3 {
      justify-content: center;
      .css-dvua67-singleValue {
        font-weight: 400;
        font-size: 20px;
      }
    }
    .css-bgvzuu-indicatorSeparator {
      display: none;
    }
  }
  .title-service {
    font-size: 18px;
    margin-left: 20px;
  }

  .slick-arrow {
    display: none !important;
  }

  .btn-right {
    float: right;
  }

  .btn-cancel {
    width: 110px;
  }

  .isValid {
    &.form-control {
      border: 1px solid red !important;
    }
    .css-bg1rzq-control {
      border: 1px solid red !important;
    }
  }
}

.RSPBprogressBar .RSPBprogression {
  background: #1e7e34 !important;
}
