$max-size: 0.7rem;
$medium-size: 0.6rem;
$min-size: 0.4rem;

body,
.mb-0 {
  font-size: $max-size !important;
}

button {
  font-size: $max-size !important;
}

.profile-group {
  // width: auto !important;
  // max-width: auto !important;
  flex: 0 !important;
  // padding-left: 0px;
  // li {
  //   margin-right: 0px !important;
  //   margin-left: 0px !important;
  // }
}

.btn-full-screen {
  display: none;
}

.sidebarnav {
  a,
  span {
    padding: 2px !important;
    font-size: $max-size !important;
  }
}

.sidebar-nav {
  margin-left: 10px;
  .nav-menu {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px !important;
  }

  ul {
    padding-left: 5px !important;
  }
  a,
  span {
    padding: 5px !important;
    font-size: $max-size !important;
  }
  i {
    width: 22px !important;
  }
}

.collapse {
  width: auto !important;
  min-width: 150px !important;
  li {
    margin-left: 10px !important;
  }
}

.activity-menu {
  left: -60px !important;
}

.menu-profile,
.dropdown-menu {
  width: auto !important;
  min-width: 150px !important;
}

.dropdown-submenu > .dropdown-menu {
  margin-left: 0px !important;
}

.form-group {
  margin-bottom: 5px !important;
  label,
  input,
  .react-select {
    font-size: $max-size !important;
  }

  // .wr-input input {
  //   height: auto;
  // }
}

.form__form-group {
  margin-bottom: 5px !important;
  label,
  input,
  .react-select {
    font-size: $max-size !important;
  }
}

.panel__title {
  font-size: $max-size !important;
}

.btn-flag {
  margin-left: 0px;
}

.sidebar-nav > ul > li {
  margin-top: 3px !important;
}

.title-account-number {
  line-height: 18px !important;
  span {
    font-size: $medium-size !important;
  }
}

.date-picker {
  z-index: auto;
}

.dashboard-page {
  margin-top: 10px !important;
}

.manage-page__form {
  margin-top: 150px !important;
}

.menu-item:hover,
.menu-item:focus,
.menu-profile:focus,
.menu-profile:hover {
  z-index: 9999999 !important;
}

.account-detail {
  font-size: $medium-size !important;
  padding: 3px !important;
}

.account-number {
  margin-right: 2px;
}

.card-title-mb {
  font-size: $max-size !important;
  display: flex;

  .title-form-header {
    margin-right: 20px;
  }
}

.btn-view-file {
  min-width: auto !important;
}

.manage-credit-card-page__form {
  margin-top: 150px !important;
}

.view-transactions-page {
  margin-top: 150px !important;
}
