.navigation {
  width: 100%;
  margin-bottom: 80px;
}

.card {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0;
  margin-left: 0px;
  margin-right: 0px;
}

.card-body {
  width: 100%;
}
.card-title {
  margin-top: -18px;
  margin-left: -20px;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.btn-link {
  color: #e46a76;
}
.btn-link:hover {
  color: darksalmon;
}

.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #dddddd;
  padding-top: 10px;
  padding-bottom: 10px;
  // font-weight: 500;
}

.global-page .card .card-body {
  border: none;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 0;
}

.container-fluid {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

.mb-0 {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.875rem;
}

.card-header {
  margin-top: -40px;
  margin-bottom: 20px;
}

.card-footer,
.card-header {
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.03);
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
  margin-left: 10px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.btn-link {
  color: #e46a76;
  padding-left: 20px;
}
