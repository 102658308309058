.btncall {
  color: white;
  background: none;
  border: none;
}

.btncall:focus {
  outline: none;
}

.main-header {
  margin-top: 80px;
}

.css-g1d714-ValueContainer {
  padding: 0px 5px !important;
}
.css-b8ldur-Input {
  padding: 0px !important;
  margin: 0px !important;
}

.css-1wa3eu0-placeholder {
  color: #b6b6b6 !important;
}
